interface ICategory {
    name: string
    icon: string
}

class Category implements ICategory {
    constructor(
        public name: string,
        public icon: string,
        public label: string
    ) {}
}

interface IEmoji {
    data: string
    category: string
    aliases: string[]
}

class Emoji implements IEmoji {
    constructor(
        public data: string,
        public category: string,
        public aliases: string[]
    ) {}
}

const icons = {
    nature: `
    <svg style="max-height:18px"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="24" fill="gray">
      <path d="M490.815 3.784C480.082 5.7 227.049 51.632 148.477 130.203c-39.153 39.153-64.259 87.884-70.694 137.218-5.881 45.081 4.347 85.929 28.878 116.708L.001 490.789 21.212 512l106.657-106.657c33.094 26.378 75.092 34.302 116.711 28.874 49.334-6.435 98.065-31.541 137.218-70.695C460.368 284.951 506.3 31.918 508.216 21.185L511.999 0l-21.184 3.784zm-43.303 39.493L309.407 181.383l-7.544-98.076c46.386-15.873 97.819-29.415 145.649-40.03zm-174.919 50.64l8.877 115.402-78.119 78.119-11.816-153.606c19.947-13.468 47.183-26.875 81.058-39.915zm-109.281 64.119l12.103 157.338-47.36 47.36c-39.246-52.892-24.821-139.885 35.257-204.698zm57.113 247.849c-26.548-.001-51.267-7.176-71.161-21.938l47.363-47.363 157.32 12.102c-40.432 37.475-89.488 57.201-133.522 57.199zm157.743-85.421l-153.605-11.816 78.118-78.118 115.403 8.877c-13.04 33.876-26.448 61.111-39.916 81.057zm50.526-110.326l-98.076-7.544L468.725 64.485c-10.589 47.717-24.147 99.232-40.031 145.653z"/>
    </svg>
    `,
    objects: `
    <svg style="max-height:18px"
      xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 792 792" fill="gray">
      <path d="M425.512 741.214H365.58c-14.183 0-25.164 11.439-25.164 25.622S351.397 792 365.58 792h59.932c15.101 0 26.54-10.981 26.54-25.164s-11.44-25.622-26.54-25.622zM472.638 671.209H319.821c-14.183 0-26.081 10.98-26.081 25.163s11.898 25.164 26.081 25.164h152.817c14.183 0 25.164-10.981 25.164-25.164s-10.982-25.163-25.164-25.163zM639.188 138.634c-25.164-42.548-59.181-76.135-102.49-101.113C493.526 12.621 446.566 0 395.771 0 320.28 0 247.19 31.684 197.205 81.445c-49.761 49.527-81.904 121.24-81.904 196.282 0 33.861 7.779 68.629 22.879 103.866 15.1 35.228 38.565 78.614 70.005 130.396 7.448 12.269 15.764 31.205 25.623 56.271 12.104 30.757 22.87 51.713 31.566 63.602 5.027 6.872 11.899 10.063 20.596 10.063h228.766c9.605 0 16.359-4.188 21.504-11.898 6.754-10.132 13.987-27.516 22.42-51.693 8.951-25.691 16.838-43.982 23.329-55.364 30.571-53.587 54.446-99.747 70.464-137.717 16.018-37.979 24.246-74.124 24.246-107.526 0-49.878-12.347-96.545-37.511-139.093zm-35.696 232.437c-15.012 34.348-36.398 76.974-65.427 126.736-9.41 16.125-18.458 37.003-26.989 63.592-3.367 10.474-7.32 20.596-11.439 30.2H300.153c-6.862-11.439-12.26-25.837-18.761-42.089-12.718-31.801-23.338-52.621-30.2-64.061-28.824-48.043-49.868-87.39-64.051-118.957s-20.537-60.859-21.044-88.766c-2.235-121.718 106.13-228.991 229.674-226.941 41.631.693 80.527 10.063 115.765 30.659 35.227 20.586 63.134 48.043 83.729 82.812 20.586 34.768 31.108 72.748 31.108 113.47-.001 27.449-7.692 58.596-22.881 93.345z"/>
    </svg>
    `,
    peoples: `
    <svg style="max-height:18px"
      xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 106.059 106.059" fill="gray">
      <path d="M90.544 90.542c20.687-20.684 20.685-54.341.002-75.024-20.688-20.689-54.347-20.689-75.031-.006-20.688 20.687-20.686 54.346.002 75.034 20.682 20.684 54.341 20.684 75.027-.004zM21.302 21.3c17.494-17.493 45.959-17.495 63.457.002 17.494 17.494 17.492 45.963-.002 63.455-17.494 17.494-45.96 17.496-63.455.003-17.498-17.498-17.496-45.966 0-63.46zM27 69.865s-2.958-11.438 6.705-8.874c0 0 17.144 9.295 38.651 0 9.662-2.563 6.705 8.874 6.705 8.874C73.539 86.824 53.03 85.444 53.03 85.444S32.521 86.824 27 69.865zm6.24-31.194a6.202 6.202 0 1 1 12.399.001 6.202 6.202 0 0 1-12.399-.001zm28.117 0a6.202 6.202 0 1 1 12.403.001 6.202 6.202 0 0 1-12.403-.001z"/>
    </svg>
    `,
    places: `
    <svg style="max-height:18px"
      xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 611.999 611.998" fill="gray">
      <path d="M596.583 15.454C586.226 5.224 573.354.523 558.423.523c-15.597 0-31.901 4.906-49.452 14.599-17.296 9.551-32.851 20.574-46.458 32.524h-.665c-2.655 2.322-10.953 10.287-25.219 24.553-14.272 14.272-26.217 26.223-35.845 36.51L112.401 26.406c-6.896-1.968-12.928.014-17.593 4.645L46.687 78.839c-4.326 4.297-5.805 9.268-4.977 15.597.829 6.287 3.979 10.627 9.629 13.607L280.32 228.839 161.514 347.978l-95.91 3.32c-4.645.164-8.637 1.643-12.276 5.311L5.872 404.397c-4.312 4.34-6.641 9.289-5.643 16.262 1.657 6.967 5.31 11.611 11.618 13.602l117.142 48.787 48.787 117.148c2.421 5.812 6.634 9.621 13.607 11.279h3.313c4.977 0 9.296-1.658 12.942-5.311l47.456-47.457c3.653-3.645 5.494-7.965 5.643-12.275l3.32-95.91 118.807-118.807 121.128 228.99c2.988 5.643 7.32 8.793 13.607 9.621 6.329.836 11.271-1.316 15.597-5.643l47.456-47.457c4.978-4.977 6.945-10.697 4.978-17.586l-82.296-288.389 59.732-59.739c10.287-10.287 21.699-24.149 33.183-45.134 5.777-10.542 10.032-20.886 12.942-31.194 5.722-20.218 3.258-44.07-12.608-59.73zm-59.4 110.176l-67.039 67.372c-5.628 5.657-6.811 11.122-4.977 17.586l81.637 288.388-22.563 22.238L403.438 292.89c-2.98-5.643-7.299-8.963-12.941-9.621-6.301-1.331-11.611.325-16.263 4.977l-141.37 141.37c-2.987 2.986-4.644 6.973-5.643 11.949l-3.32 95.904-22.896 23.236-41.48-98.566c-1.331-4.645-4.553-8.184-9.629-10.287L51.338 411.03l23.229-22.895 95.578-3.654c5.643-.99 9.622-2.654 12.276-5.309l141.37-141.371c4.651-4.645 6.308-9.954 4.984-16.262-.666-5.643-3.986-9.954-9.629-12.942L90.829 87.47l22.231-22.238 288.389 81.637c6.464 1.833 11.951.666 17.587-4.977l28.545-28.539 26.217-25.884 11.278-11.285 1.331-.666c27.873-23.895 55.088-38.16 72.016-38.16 5.969 0 9.954 1.324 11.611 3.979 18.917 18.585-21.099 72.484-32.851 84.293z"/>
    </svg>
    `
}

export const customCategories: ICategory[] = [
    new Category("Peoples", icons["peoples"], "Peoples"),
    new Category("Nature", icons["nature"], "Nature"),
    new Category("Objects", icons["objects"], "Objects"),
    new Category("Places", icons["places"], "Places"),
]

export const customEmojis : IEmoji[] = [
    new Emoji("😀", "Peoples", ["grinning"]),
    new Emoji("😉", "Peoples", ["wink"]),
    new Emoji("😍", "Peoples", ["heart_eyes"]),
    new Emoji("👉", "Peoples", ["point_right"]),
    new Emoji("🏃", "Peoples", ["running_man", "runner", "running"]),
    new Emoji("👪", "Peoples", ["family_man_woman_boy", "family"]),
    new Emoji("☂️", "Peoples", ["open_umbrella"]),
    new Emoji("🎄", "Nature", ["christmas_tree"]),
    new Emoji("🌴", "Nature", ["palm_tree"]),
    new Emoji("✨", "Nature", ["sparkles"]),
    new Emoji("⚡️", "Nature", ["zap"]),
    new Emoji("☀️", "Nature", ["sunny"]),
    new Emoji("🌧", "Nature", ["cloud_with_rain"]),
    new Emoji("❄️", "Nature", ["snowflake"]),
    new Emoji("🚴", "Peoples", ["biking_man", "bicyclist"]),
    new Emoji("🚗", "Places", ["car", "red_car"]),
    new Emoji("🚀", "Places", ["rocket"]),
    new Emoji("🏠", "Places", ["house"]),
    new Emoji("🏥", "Places", ["hospital"]),
    new Emoji("📲", "Objects", ["calling"]),
    new Emoji("🖥", "Objects", ["desktop_computer"]),
    new Emoji("📞", "Objects", ["telephone_receiver"]),
    new Emoji("⏱", "Objects", ["stopwatch"]),
    new Emoji("⏳", "Objects", ["hourglass_flowing_sand"]),
    new Emoji("💡", "Objects", ["bulb"]),
    new Emoji("⚖️", "Objects", ["balance_scale"]),
    new Emoji("🛠", "Objects", ["hammer_and_wrench"]),
    new Emoji("🛡", "Objects", ["shield"]),
    new Emoji("🎁", "Objects", ["gift"]),
    new Emoji("🎉", "Objects", ["tada"]),
    new Emoji("📝", "Objects", ["memo", "pencil"]),
    new Emoji("🔒", "Objects", ["lock"]),
    new Emoji("💙", "Objects", ["blue_heart"]),
    new Emoji("🔔", "Objects", ["bell"])
]
