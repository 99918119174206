import checkRightCreateMessage from '@/router/guards/checkRightCreateMessage'
import checkRightDuplicateMessage from '@/router/guards/checkRightDuplicateMessage'
import checkRightEditMessage from '@/router/guards/checkRightEditMessage'
import checkRightEditUser from '@/router/guards/checkRightEditUser'
import checkRightManageExports from '@/router/guards/checkRightManageExports'
import checkRightManageMilestone from '@/router/guards/checkRightManageMilestone'
import fetchActivationToken from '@/router/guards/fetchActivationToken'
import fetchDataAndRedirect from '@/router/guards/fetchDataAndRedirect'
import {loadAccounts, loadMe} from '@/router/guards/loadAccounts'
import {loadCurrentMessage, loadMessages, loadOriginalMessage, unloadCurrentMessage} from '@/router/guards/loadMessages'
import {loadMilestones} from '@/router/guards/loadMilestones'
import {resetStore} from '@/store/actions/globalActions'

import {
    AllAccounts,
    AllCampaigns,
    AllMessages,
    AppTemplate,
    CreateMessageConditions,
    CreateMessageDraft,
    CreateMessageType,
    CreateMessageValidation,
    DuplicateMessage,
    EditAccount,
    EditCampaign,
    EditMessage,
    ExportCampaigns,
    Login,
    CreatePassword,
    ResetPassword,
    ReceptionTemplate,
    ForgottenPassword,
} from '@/ui/components'

import AllRoles from '@/ui/screens/AllRoles.vue'
import Vue from 'vue'
import Router from 'vue-router'
import multiguard from 'vue-router-multiguard'

Vue.use(Router)


const router = new Router({
    // We use hash mode because, with `history` mode, when we reload the page,
    // the server returns a 404 error. We need the URLs to stay only on the
    // front. The solution with `history` mode and a back controller redirecting
    // to `/` was considered too complicated.
    // http://edivgitlab.swordgroup.lan/kami/outside-generique/outside/issues/48
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/login',
            component: ReceptionTemplate,
            children: [
                {path: '', component: Login},
            ],
        },
        {
            path: '/create-password/:token',
            component: ReceptionTemplate,
            children: [
                {path: '', component: CreatePassword, props: true, beforeEnter: fetchActivationToken},
            ],
        },
        {
            path: '/forgotten-password',
            component: ReceptionTemplate,
            children: [
                {path: '', component: ForgottenPassword},
            ],
        },
        {
            path: '/reset-password/:token',
            component: ReceptionTemplate,
            children: [
                {path: '', component: ResetPassword, props: true, beforeEnter: fetchActivationToken},
            ],
        },
        {
            path: '/',
            component: AppTemplate,
            beforeEnter: fetchDataAndRedirect,
            children: [
                {
                    path: '',
                    redirect: '/messages',
                },
                {
                    path: 'messages',
                    component: AllMessages,
                    beforeEnter: multiguard([
                        loadMe,
                        loadMilestones,
                        loadMessages,
                    ]),
                },
                {
                    path: 'create-message',
                    component: CreateMessageType,
                    beforeEnter: multiguard([
                        loadMe,
                        unloadCurrentMessage,
                        checkRightCreateMessage,
                    ]),
                },
                {
                    path: 'create-message/:id/step-1-type',
                    component: CreateMessageType,
                    props: true,
                    beforeEnter: multiguard([
                        loadMe,
                        loadCurrentMessage( {step: 1}),
                        checkRightEditMessage,
                    ]),
                },
                {
                    path: 'create-message/:id/step-2-draft',
                    component: CreateMessageDraft,
                    props: true,
                    beforeEnter: multiguard([
                        loadMe,
                        loadCurrentMessage( {step: 2}),
                        checkRightEditMessage,
                    ]),
                },
                {
                    path: 'create-message/:id/step-3-conditions',
                    component: CreateMessageConditions,
                    props: true,
                    beforeEnter: multiguard([
                        loadMe,
                        loadMilestones,
                        loadCurrentMessage( {step: 3}),
                        checkRightEditMessage,
                    ]),
                },
                {
                    path: 'create-message/:id/step-4-validation',
                    component: CreateMessageValidation,
                    props: true,
                    beforeEnter: multiguard([
                        loadMe,
                        loadCurrentMessage( {step: 4}),
                        checkRightEditMessage,
                    ]),
                },
                {
                    path: 'messages/:id',
                    component: EditMessage,
                    props: true,
                    beforeEnter: multiguard([
                        loadMe,
                        loadMilestones,
                        loadCurrentMessage( {step: 0}),
                        checkRightEditMessage,
                    ]),
                },
                {
                    path: 'messages/:id/duplicate',
                    component: DuplicateMessage,
                    props: true,
                    beforeEnter: multiguard([
                        loadMe,
                        loadMilestones,
                        loadOriginalMessage,
                        checkRightDuplicateMessage,
                    ]),
                },
                {
                    path: 'create-account',
                    component: EditAccount,
                    props: {creating: true},
                    beforeEnter: multiguard([
                        loadMe,
                        checkRightEditUser,
                    ]),
                },
                {
                    path: 'campaigns',
                    component: AllCampaigns,
                    beforeEnter: multiguard([
                        loadMe,
                        loadMilestones,
                    ]),
                },
                {
                    path: 'campaigns/export',
                    component: ExportCampaigns,
                    beforeEnter: multiguard([
                        loadMe,
                        loadMilestones,
                        checkRightManageExports,
                    ]),
                },
                {
                    path: 'create-campaign',
                    component: EditCampaign,
                    beforeEnter: multiguard([
                        loadMe,
                        loadMilestones,
                        checkRightManageMilestone,
                    ]),
                },
                {
                    path: 'campaigns/:milestoneId',
                    component: EditCampaign,
                    props: true,
                    beforeEnter: multiguard([
                        loadMe,
                        loadMilestones,
                        checkRightManageMilestone,
                    ]),
                },
                {
                    path: 'accounts',
                    component: AllAccounts,
                    beforeEnter: multiguard([
                        loadMe,
                        loadAccounts,
                        checkRightEditUser,
                    ]),
                },
                {
                    path: 'accounts/:id',
                    component: EditAccount,
                    props: true,
                    beforeEnter: multiguard([
                        loadMe,
                        loadAccounts,
                        checkRightEditUser,
                    ]),
                },
                {
                    path: 'profile',
                    component: EditAccount,
                    props: {selfEditing: true},
                    beforeEnter: multiguard([
                        loadMe,
                        loadAccounts,
                    ]),
                },
                {
                    path: 'roles',
                    component: AllRoles,
                    beforeEnter: multiguard([
                        loadMe,
                    ]),
                },
            ],
        },
    ],
})

export default router

