import { render, staticRenderFns } from "./MessageContentStatic.vue?vue&type=template&id=8eb11d44&scoped=true"
import script from "./MessageContentStatic.vue?vue&type=script&lang=ts"
export * from "./MessageContentStatic.vue?vue&type=script&lang=ts"
import style0 from "./MessageContentStatic.vue?vue&type=style&index=0&id=8eb11d44&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8eb11d44",
  null
  
)

export default component.exports